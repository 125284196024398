import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'
import HeaderSEO from '../components/partials/HeaderSEO'
import Layout from '../components/Layout'
import AboveFooterSection from '../components/AboveFooterSection'

import { StaticImage } from 'gatsby-plugin-image'

const webinars = () => {
  const data = useStaticQuery(graphql`
    query WebinarsQuery {
      allContentfulWebinar {
        edges {
          node {
            date
            title
            slug
            author {
              name
            }
          }
        }
      }
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Webinars" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node
  const webinarslist = data.allContentfulWebinar.edges

  // Format used to render the date
  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  } as const

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <StaticImage
        className="home-fixed-top"
        src="../resources/images/homepage/hero_lens.svg"
        placeholder="blurred"
        alt="Hero Lenses"
      />
      <section className="pt-5 pb-5 mt-5 ">
        <div className="container-1">
          <div className="d-flex flex-wrap mt-3">
            <div className="col-md-6 pt-0">
              <p className="title breadcrumb-text pt-4">
                <a href="/resources/" className="title_text">
                  RESOURCES
                </a>
              </p>
              <h1>Webinars</h1>
            </div>
          </div>
        </div>
      </section>

      <section id="summary pt-5 pb-5">
        <div className="container-1 pt-5 mb-5">
          <div className="d-flex flex-wrap">
            {webinarslist.map(({ node }) => {
              // Grabbing the date from Contentful Node
              let tempDate = node.date
              // Create new Date object from the tempDate
              let dateToDisplay = new Date(tempDate)
              // Getting the year from the date - used in the url
              let year = dateToDisplay.getFullYear()
              return (
                <div className="col-sm-12 col-md-4 mb-4" key={uuidv4()}>
                  <div className="ds-card height-280">
                    <span className="ds-card-label-blue">WEBINAR</span>
                    <h2 className="ds-card-title webinar-h2">
                      <span className="f-10">
                        {' '}
                        {dateToDisplay.toLocaleDateString(
                          'en-US',
                          dateOptions
                        )}{' '}
                      </span>
                      <br />
                      {node.title} {node.author.name}
                    </h2>
                    <div className="text-center">
                      <div className="mt-3 d-flex justify-content-center">
                        <a
                          href={`/webinars/${year}/${node.slug}`}
                          className="ds-card-button link-text"
                        >
                          GO TO WEBINAR
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* END OF FOR ABOVE ^ */}
          </div>
        </div>
      </section>

      <AboveFooterSection
        text={'Ready to get started with Lenses?'}
        ctaText={'Try now for free'}
        ctaLink="/start/"
      />
    </Layout>
  )
}

export default webinars
